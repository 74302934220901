@font-face {
  font-family: "ageer";
  src: url("./assets/ageer2/Ageer-PersonalUse.otf");
  /* src: local("Euclid Circular A Bold"), local("EuclidCircularA-Bold"),
    url("./assets/font/EuclidCircularA-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Bold.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Bold.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Bold.ttf") format("truetype"); */
  font-weight: bold;
  font-style: normal;
}


